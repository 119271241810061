'use client';

import { useTranslation } from 'react-i18next';
import { FullScreenError } from './components';

export function PageNotFoundErrorSecure() {
  const { t } = useTranslation();

  return (
    <FullScreenError
      variant="default"
      title={t('common.errorBoundary.title.pageNotFound')}
      message={t('common.errorBoundary.message.pageNotFound')}
    />
  );
}
