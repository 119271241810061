'use client';

import { Button } from '@cxnpl/ui';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'solito/navigation';
import { FullScreenError } from './components';

export function PageNotFoundError() {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <FullScreenError
      variant="with-logo-and-background"
      title={t('common.errorBoundary.title.pageNotFound')}
      message={t('common.errorBoundary.message.globalPageNotFound')}
    >
      <Button
        onPress={() => {
          router.push('/home');
        }}
      >
        {t('common.errorBoundary.buttons.backToHome')}
      </Button>
    </FullScreenError>
  );
}
