'use client';

import { Main } from '@cxnpl/ui';
import type { ReactNode } from 'react';

/**
 * Use this component to wrap a page. The content will be stretched to fill the page.
 */

interface BasePageWrapperProps {
  children: ReactNode;
}

export function BasePageWrapper({ children }: BasePageWrapperProps) {
  return (
    <Main
      backgroundColor="$background/app"
      flexShrink={1}
      flexGrow={1}
      paddingHorizontal="$space.xl"
      paddingBottom="$space.8xl"
      paddingTop="$space.2xl"
      $laptop={{
        paddingTop: '$space.xl',
      }}
    >
      {children}
    </Main>
  );
}
